:root {
	--font-family: Helvetica, sans-serif;
	--font-family-bold: Helvetica, sans-serif;
	// default font-sizes are defined in _common-typography.scss. Override the default font sizes here.
	--font-size-xl6: 3.8rem;
	--font-size-xl7: 3.8rem;
	--font-size-xl8: 3.8rem;
	--font-size-xl9: 3.8rem;
	--font-weight-bold-xl10: bold;
	--font-weight-bold-xl9: bold;
	--font-weight-bold-xl8: bold;
	--font-weight-bold-xl7: bold;
	--font-weight-bold-xl6: normal;
	--font-weight-bold-xl5: bold;
	--font-weight-bold-xl4: bold;
	--font-weight-bold-xl3: bold;
	--font-weight-bold-xl2: bold;
	--font-weight-bold-xl: bold;
	--font-weight-bold-large: bold;
	--font-weight-bold-regular: bold;
	--font-weight-bold-small: bold;
	--font-weight-bold-xsmall: bold;
	--font-weight-bold-xxsmall: bold;
	--font-family-xl6: "heroic-condensed-medium-oblique", sans-serif;
	--font-family-bold-xl6: "heroic-condensed-medium-oblique", sans-serif;
}

@media (min-width: $md) {
	:root {
		--font-family-xl9: "heroic-condensed-medium-oblique", sans-serif;
		--font-weight-bold-xl9: normal;
		--font-family-bold-xl9: "heroic-condensed-medium-oblique", sans-serif;
	}
}
